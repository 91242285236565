<script setup>
import { useRoute, useRouter } from '@/utils/webApi'
import { getPctrackerVersionHistory } from '@/api/pctrakerop131'
import descriptions from '@/components/descriptions/descriptions.vue'
import { computed, reactive } from 'vue'
import { mergeDesDataOfPort } from '@/utils/util'
import TableMixin from '@/views/employee/Hooks/TableMixin'
const router = useRouter()
const columnConfig = [
  {
    key: 'createTime',
    name: '调整时间',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150, // 默认宽度，像素,
    align: 'left'
  },
  {
    key: 'createUser',
    name: '调整人',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: null,
    width: 150, // 默认宽度，像素,
    align: 'left'
  },
  {
    key: 'updateContent',
    name: '调整内容',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: null,
    width: 240, // 默认宽度，像素,
    align: 'left'
  },
  {
    key: 'before',
    name: '调整前',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: null,
    width: null, // 默认宽度，像素,
    align: 'left'
  },
  {
    key: 'after',
    name: '调整后',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: null,
    width: null, // 默认宽度，像素,
    align: 'left'
  }
]
const Route = useRoute()
const query = Route.query
let { pageConfig, pageChange, pageSizeChange, currentPage } =
  TableMixin(getEditHistory)
const editHistoryData = reactive({
  listData: [
    {
      title: 'Basic Info',
      column: 4,
      content: [
        {
          label: 'HC Nature',
          key: 'hcNature',
          value: '',
          lg: 8
        },
        {
          label: 'HFM Code',
          key: 'hfmCode',
          value: '',
          lg: 8
        },
        {
          label: 'Business Unit',
          key: 'businessUnitName',
          value: '',
          lg: 8
        },
        {
          label: 'Team',
          key: 'team',
          value: '',
          lg: 8
        },
        {
          label: 'Chinese Name',
          key: 'chineseName',
          value: '',
          lg: 8
        },
        {
          label: 'English Name',
          key: 'englishName',
          value: '',
          lg: 8
        },
        {
          label: 'Position',
          key: 'position',
          value: '',
          lg: 8
        },
        {
          label: 'Start Date',
          key: 'startDate',
          value: '',
          lg: 8
        }
      ]
    }
  ],
  tableData: [],
  pageConfig,
  loading: false
})
const isBaseInformShow = computed(() => {
  return query.target !== 'op151' && query.target !== 'op152'
})
function getEditHistory() {
  editHistoryData.loading = true
  if (query) {
    const data = {
      ...query,
      ...pageConfig
    }
    getPctrackerVersionHistory(data).then((res) => {
      editHistoryData.loading = false
      editHistoryData.pageConfig.total = 0
      if (res && res.code === 200) {
        mergeDesDataOfPort(editHistoryData.listData, res.data)
        if (res.data.updateHistoryVo) {
          editHistoryData.tableData = res.data.updateHistoryVo.list
          editHistoryData.pageConfig.total = res.data.updateHistoryVo.total
        }
      }
    })
  }
}
getEditHistory()
const backPage = () => {
  router.go(-1)
}
</script>

<template>
  <div class="editHistory">
    <div class="editHistory__title">
      <div>调整历史</div>
      <el-button @click="backPage">返回</el-button>
    </div>
    <div class="editHistory__content">
      <descriptions
        v-if="isBaseInformShow"
        :descriptionData="editHistoryData.listData"
      />
      <PocTable
        ref="tTableRef"
        v-loading="editHistoryData.loading"
        :data="editHistoryData.tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
        stripe
        :max-height="1000000000000"
      >
        <el-table-column
          v-for="item in columnConfig"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
          :align="item.align"
          show-overflow-tooltip
        ></el-table-column>
      </PocTable>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.editHistory {
  background: white;
  padding: 20px;
  &__title {
    display: flex;
    font-size: 21px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #303133;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  &__content {
  }
}
</style>
